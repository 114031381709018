.App {
  text-align: center;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --dark-color: hsl(var(--hue), 100%, 9%);
  --light-color: hsl(var(--hue), 95%, 98%);
  --base: hsl(var(--hue), 95%, 50%);
  --complimentary1: hsl(var(--hue-complimentary1), 95%, 50%);
  --complimentary2: hsl(var(--hue-complimentary2), 95%, 50%);

  --font-family: "Poppins", system-ui;

  --bg-gradient: linear-gradient(
    to bottom,
    hsl(var(--hue), 95%, 99%),
    hsl(var(--hue), 95%, 84%)
  );
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: violet;
}

::selection {
  color: black;
  background: violet;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-width: 100%;
  display: grid;
  place-items: center;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
  color: black;
  background: rgb(255,0,211);
  background: linear-gradient(91deg, rgba(255,0,211,0.8) 0%, rgba(0,212,255,0.8) 75%, rgba(255,255,255,0.8) 100%); 
}


.orb-canvas {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

strong {
  font-weight: 600;
}

.landing_hero {
  height: 95vh;
}

.overlay {
  min-width: 100%;
  padding: 8rem 6rem;
  display: flex;
  align-items: center;
  background: #ffffff60;
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.overlay__inner {
  max-width: 90rem;
  font-size: 2.25rem;
}

.overlay__inner1 {
  max-width: 100%;
}

.overlay_img {
  border-radius: 40px;
  position:relative;
  display: flex;
  max-width: 600px;
  max-height: 400px;
}

.landing-top {
  padding: 80px 0 0;
}

.p1 {
  border-radius: 25px;
  max-width: 25vw;
  float: right;
  padding: 10px;
}

.p2 {
  border-radius: 45px;
  max-width: 25vw;
  float: left;
  padding: 30px;
}

.p3 {
  border-radius: 50px;
  max-width: 35vw;
  float: right;
  padding: 30px;
}

.p4 {
  border-radius: 40px;
  max-width: 35vw;
  float:left;
  padding: 10px;
}

.p5 {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 80px;
}

.p6 {
  max-width: 22vw;
  border-radius: 20px;
  margin: 10px;
}

.p7 {
  max-width: 15vw;
  border-radius: 20px;
  margin: 0px 15px;

}

.p8 {
  max-width: 15vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.p9 {
  display: block;
  margin: auto;
  max-width: 80vw;
  border-radius: 20px;
}

.p10 {
  max-width: 40vw;
  border-radius: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.p11 {
  width: 45vw;
  padding: 5px;
  border-radius: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.p12 {
  width: 35vw;
  border-radius: 20px;
}

h2 {
  font-size: 35px;
  text-align: center;
}

h3 {
  font-size: 20px;
}

.h4 {
  font-size: 17px;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
  padding-right: 40px ;
}

.ordered-list {
  font-size: 17px;
  padding-left: 25px;
}

.unordered-list {
  font-size: 20px;
  padding-left: 25px;
}

.odl {
  width: 32vw;
  border-radius: 25px;
}

.flex-work {
  display: flex;
  justify-content: space-around;
}

.flexi {
  max-width: 80vw;
  border-radius: 20px;
}

.pflexi {
  text-align: center;
  font-size: 30px;
}

.unordered {
  padding-left: 30px;
  font-size: 17px;
}

.list-side {
  font-size: 18px;
	display: inline;
  padding: 120px;
  font-weight: 700;
}

.list-side1 {
  font-size: 40px;
	display: inline;
  padding: 150px;
}

.zoom {
  max-width: 500px;
  font-size: 20px;
}

.boxy {
  border: 2px solid #d400ff;
  border-radius: 1.1vmax;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5vmax;
  background-color: #1b1b1b;
  color: #d400ff;
}

textarea {
  border: 2px solid #d400ff;
  border-radius: 1.1vmax;
  width: 100%;
  height: 20vh;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5vmax;
  background-color: #1b1b1b;
  color: #d400ff;
}

button  {
  background-color: #1b1b1b;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5vmax;
  border-radius: 1.1vmax;
}

.text-gradient {
  background-image: linear-gradient(
    45deg,
    var(--base) 25%,
    var(--complimentary2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}


.overlay__description {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 3rem;
}

.overlay__btns {
  width: 100%;
  max-width: 190rem;
  display: flex;
}

.overlay__btn {
  width: 500px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--light-color);
  background: var(--dark-color);
  border: none;
  border-radius: 0.5rem;
  cursor:pointer;
  transition: transform 500ms ease;
  outline-color: hsl(var(--hue), 95%, 50%);
}

.overlay__btn--colors:hover {
  transform: scale(1.05);
  cursor: pointer;
  cursor:pointer;
}

.overlay__btn--transparent {
  background: transparent;
  color: var(--dark-color);
  border: 2px solid var(--dark-color);
  border-width: 2px;
  margin-right: 0.75rem;
  outline: none;
}

@media only screen and (max-width: 1140px) {
  .overlay {
    padding: 8rem 4rem;
    max-width: 1000px;
  }
}



@media only screen and (max-width: 840px) {
  body {
    padding: 1.5rem;
  }

  .overlay {
    padding: 4rem;
    height: auto;
  }

  .overlay__title {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .overlay__description {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .overlay {
    padding: 1.5rem;
  }

  .overlay__btns {
    flex-wrap: wrap;
  }

  .overlay__btn {
    width: 100%;
    font-size: 0.75rem;
    margin-right: 0;
  }

  .overlay__btn:first-child {
    margin-bottom: 1rem;
  }
}